#products {
    /*background-color: white;*/
    /*background-image: linear-gradient(
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        url('../assets/images/background.jpg');*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.product-title h1 {
    font-family: 'ethnocentric';
}

.product-category h2 {
    /*font-family: 'ethnocentric';*/
    text-align: center;
    padding: 30px 0 0 0;
    margin: 0;
    color: var(--alt-red);
}

.products-list {
    display: flex;
    justify-content: center;
}

.product-card {
    background: white;
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 400px;
    margin: 20px;
}

.prod-img-cnt {
    height: 200px;
}

.prod-img-cnt img {
    width: 100%;
    height: 100%;
}

.prod-text-cnt {
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.prod-desc h4 {
    color: var(--alt-red);
    text-align: center;
}

.prod-title h5 {
    margin-bottom: 5px;
    font-family: 'ethnocentric';
}

.prod-summary {
    line-height: 1.2;
    font-size: 0.8em;
    height: 100%;
}

.product-wrapper {
    padding: 40px 0;
}

.product-wrapper .product-title {
    padding: 20px;
    display: flex;
}

.product-wrapper .product-title h1 {
    margin: auto;
    text-align: center;
}

.product-wrapper .full-product-img-ctn {
    margin: auto;
    max-width: 800px;
}

.product-wrapper .full-product-img-ctn .prod-img {
    padding: 0px 45px;
}

.product-wrapper .full-product-img-ctn img {
    max-width: 100%;
    max-height: 500px;
    margin: auto;
    cursor: pointer;
}

.product-wrapper .full-product-img-ctn .slick-slider {
    width: inherit;
}

.product-wrapper .full-product-desc-ctn {
    margin: auto;
    max-width: 800px;
    overflow-y: auto;
    padding: 120px 30px 0px 30px;
}

.product-wrapper .product-actions {
    display: flex;
    padding: 20px;
}

.product-wrapper .product-actions a {
    margin: auto;
}

.slick-slide div {
    display: block;
}

.slick-track {
    display: flex;
}
.slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}

.product-wrapper .slick-prev {
    left: 0;
}

.product-wrapper .slick-next {
    right: 0;
}

.prod-carousel-arrow {
    zoom: 2;
}

.prod-carousel-arrow:before {
    color: var(--main-red);
}
