:root {
    --black: #000000;
    --off-black: #1a1a1a;
    --dark-grey: #333333;
    --light-grey: #666666;
    --off-white: #fefefe;
    --main-red: #960303;
    --alt-red: #ff0000;
}

@font-face {
    font-family: 'ethnocentric';
    src: local('ethnocentric'),
        url(../fonts/ethnocentric_rg.otf) format('truetype');
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--off-white);
}

#header .logo img {
    height: 100%;
}

.product-menu-cont {
    position: relative;
}

#header .product-menu-cont a {
    padding: 0 2em;
}

.products-sub-menu {
    white-space: nowrap;
    background-color: var(--off-black);
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
    box-shadow: inset 0 0 0 2px #fff;
    padding: 20px;
    line-height: 1.4em;
}

.products-sub-menu::after {
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    content: '';
    width: 15px;
    height: 15px;
    background-color: var(--off-black);
    box-shadow: inset 6px 6px 0 -4px #fff;
}

#banner {
    color: white;
    text-align: center;
}

#banner h1 {
    text-transform: none;
}

#examples header h3 {
    margin: 0;
}

@-webkit-keyframes new-tag-scale {
    from {
        transform: translate(-50%, -50%) rotate(315deg) scale(1, 1);
    }
    to {
        transform: translate(-50%, -50%) rotate(315deg) scale(1.1, 1.1);
    }
}

@-moz-keyframes new-tag-scale {
    from {
        transform: translate(-50%, -50%) rotate(315deg) scale(1, 1);
    }
    to {
        transform: translate(-50%, -50%) rotate(315deg) scale(1.1, 1.1);
    }
}

@keyframes new-tag-scale {
    from {
        transform: translate(-50%, -50%) rotate(315deg) scale(1, 1);
    }
    to {
        transform: translate(-50%, -50%) rotate(315deg) scale(1.1, 1.1);
    }
}

#image-tag {
    background: #fdaeae;
    position: relative;
}

#image-tag span {
    width: min-content;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    border-radius: 20px;
    border: 2px solid var(--main-red);
    box-shadow: 0 0 90px -20px var(--main-red);
    background-color: var(--off-white);
    padding: 15px 25px;
    zoom: 0.6;
    left: 50px;
    top: 50px;
    -webkit-animation: new-tag-scale 2s ease-in alternate infinite;
    -moz-animation: new-tag-scale 2s ease-in alternate infinite;
    animation: new-tag-scale 2s ease-in alternate infinite;
}

#image-tag h2 {
    text-align: center;
    font-family: 'ethnocentric';
    letter-spacing: 10px;
    margin: 0 -10px 0 0;
}

#product-title {
    display: inline-block;
    position: relative;
    letter-spacing: 0.4em;
    border-bottom: 3px solid var(--alt-red);
    padding: 50px 80px 10px 80px;
    margin-bottom: 5vh;
    left: 50%;
    transform: translate(-50%);
}

/* #product-title:before,
#product-title:after {
    top: -8px;
    z-index: 5;
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    padding: 20px;
    top: 0;
}

#product-title:after {
    right: -48px;
    border-top: 8px solid var(--main-red);
    border-left: 8px solid var(--main-red);
    border-top-left-radius: 10px;
}

#product-title:before {
    left: -48px;
    border-top: 8px solid var(--main-red);
    border-right: 8px solid var(--main-red);
    border-top-right-radius: 10px;
} */

#product-title h1 {
    font-family: 'ethnocentric';
    color: var(--alt-red);
}

#examples header {
    font-family: 'ethnocentric';
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 20px;
}

#examples label {
    font-family: 'ethnocentric';
    line-height: 1.3;
    margin: 1em 0 0 0;
    color: var(--alt-red);
}

#examples .content .description .image {
    padding-bottom: 3vh;
}

#examples .content .image {
    display: inherit;
    width: 600px;
}

#examples .image a {
    margin: auto;
}

#examples .image img {
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0px 0px 20px 5px #c4c4c4;
}

#examples .content {
    display: flex;
    height: 1000px;
    margin: auto;
    justify-content: center;
}

#examples .content .description a {
    margin-right: 50%;
}

#examples .content .description {
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 80px;
}

#examples p {
    line-height: 1.5em;
}

#examples .image span label {
    text-align: center;
}

#examples .slick-dots {
    bottom: 5px;
}

#burger .bm-item-list a {
    text-align: left;
    padding: 0;
}

#burger {
    display: none;
}

#main h3 {
    font-family: 'ethnocentric';
    font-size: 1.2em;
}

#main h4 {
    color: var(--alt-red);
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 25px;
    top: 25px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #666666; /*#373a47*/
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: var(--main-red); /*a90000*/
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #fefefe; /*#bdc3c7*/
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    top: 0;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: var(--off-black); /*#373a47*/
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #666666; /*#373a47*/
}

/* Wrapper for item list */
.bm-item-list {
    color: #fefefe; /*b8b7ad*/
    padding: 0.8em;
}

/* Individual item */
#burger .bm-item-list .bm-item {
    display: inline-block;
    padding: 5px;
    text-align: center;
}

/* Styling of overlay */
.bm-overlay {
    background: #000000; /*rgba(0, 0, 0, 0.3)*/
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.message-sent-conf {
    display: inline;
    padding-left: 10px;
}

.boxed {
    box-shadow: inset 0 0 0 2px #fff;
    padding: 10px;
    background: #000000c2;
}

.boxed p {
    margin: 1em 0 1em 0;
}

.content {
    display: flex;
    flex-direction: row;
}

article.alt {
    background-color: #f7f7f7;
}

.box-shadow {
    box-shadow: 0 0 15px #cccccc;
}

#gallery {
    height: 600px;
}

#gallery .gallery-item {
    height: 400px;
    width: 400px;
    padding: 10px;
}

#gallery .gallery-item div {
    margin: 10px;
    padding: 10px;
    height: 100%;
    display: flex;
}

#gallery .gallery-item img {
    width: 300px;
    margin: auto;
}

#gallery .slick-slider {
    box-shadow: 3px 3px 10px #eaeaea;
    background: #eaeaea;
}

.carousel-arrow {
    z-index: 1;
}

.carousel-arrow.home {
    zoom: 1.5;
}

.carousel-arrow.home:before {
    color: var(--main-red);
}

.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.9;
    z-index: 6;
}

.overlay-img {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 7;
    max-height: 90% !important;
    max-width: 90% !important;
    width: unset !important;
    height: unset !important;
    box-shadow: 0px 0px 2px 4px #efefef;
}

.new-tag {
    padding-right: 10px;
}

.popover {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    max-width: 600px;
    min-height: 400px;
    max-height: 1000px;
    background-color: white;
    box-shadow: inset 0 0 15px -10px var(--main-red);
    border: 5px solid var(--main-red);
    padding: 10px;
}

.popover span {
    opacity: 1;
    text-align: left;
    color: var(--main-red);
}

.popover-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid #dedede;
    align-items: center;
}

.popover-title span {
    font-style: italic;
    font-size: 1.1em;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.popover-logo {
    width: 50px;
    height: 50px;
}

.popover-body p {
    margin: 0;
}

.popover-body {
    line-height: 1.5em;
    padding: 30px 5px 30px 5px;
    overflow-y: auto;
}

.popover-footer {
    padding: 10px;
}

.disclaimer {
    color: #fefefe;
    margin-bottom: 2em;
    display: inline-block;
}

@media screen and (max-width: 1680px) {
    #examples .content {
        flex-direction: column;
        padding: 0 50px;
    }

    #examples .description {
        margin: 0 auto;
        max-width: 600px;
        padding-bottom: 40px;
        padding-right: 0 !important;
    }

    #examples .description header {
        margin: 0 auto;
    }

    #examples .content .description a {
        margin: 0 auto;
    }

    #examples .image {
        margin: 0 auto;
        width: 600px;
    }

    #examples .alt .content {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 980px) {
    #burger {
        display: inline-block;
    }
}

@media screen and (max-width: 650px) {
    #examples .content .image {
        width: unset;
    }

    #examples .content .description {
        width: unset;
    }

    #examples .image {
        flex-direction: column;
    }
}
