@import url(font-awesome.min.css);
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700|Pacifico');
/*
	Introspect by TEMPLATED
	templated.co @templatedco
	Released for free under the Creative Commons Attribution 3.0 license (templated.co/license)
*/

/* Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    -webkit-text-size-adjust: none;
}

/* Box Model */

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* Containers */

.container {
    margin-left: auto;
    margin-right: auto;
}

.container.\31 25\25 {
    width: 100%;
    max-width: 100em;
    min-width: 80em;
}

.container.\37 5\25 {
    width: 60em;
}

.container.\35 0\25 {
    width: 40em;
}

.container.\32 5\25 {
    width: 20em;
}

.container {
    width: 80em;
}

@media screen and (max-width: 1680px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 100em;
        min-width: 80em;
    }

    .container.\37 5\25 {
        width: 60em;
    }

    .container.\35 0\25 {
        width: 40em;
    }

    .container.\32 5\25 {
        width: 20em;
    }

    .container {
        width: 80em;
    }
}

@media screen and (max-width: 1280px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 81.25em;
        min-width: 65em;
    }

    .container.\37 5\25 {
        width: 48.75em;
    }

    .container.\35 0\25 {
        width: 32.5em;
    }

    .container.\32 5\25 {
        width: 16.25em;
    }

    .container {
        width: 65em;
    }
}

@media screen and (max-width: 980px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 112.5%;
        min-width: 90%;
    }

    .container.\37 5\25 {
        width: 67.5%;
    }

    .container.\35 0\25 {
        width: 45%;
    }

    .container.\32 5\25 {
        width: 22.5%;
    }

    .container {
        width: 90%;
    }
}

@media screen and (max-width: 736px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 112.5%;
        min-width: 90%;
    }

    .container.\37 5\25 {
        width: 67.5%;
    }

    .container.\35 0\25 {
        width: 45%;
    }

    .container.\32 5\25 {
        width: 22.5%;
    }

    .container {
        width: 90% !important;
    }
}

@media screen and (max-width: 480px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 112.5%;
        min-width: 90%;
    }

    .container.\37 5\25 {
        width: 67.5%;
    }

    .container.\35 0\25 {
        width: 45%;
    }

    .container.\32 5\25 {
        width: 22.5%;
    }

    .container {
        width: 90% !important;
    }
}

/* Grid */

.row {
    border-bottom: solid 1px transparent;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.row > * {
    float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.row:after,
.row:before {
    content: '';
    display: block;
    clear: both;
    height: 0;
}

.row.uniform > * > :first-child {
    margin-top: 0;
}

.row.uniform > * > :last-child {
    margin-bottom: 0;
}

.row.\30 \25 > * {
    padding: 0 0 0 0em;
}

.row.\30 \25 {
    margin: 0 0 -1px 0em;
}

.row.uniform.\30 \25 > * {
    padding: 0em 0 0 0em;
}

.row.uniform.\30 \25 {
    margin: 0em 0 -1px 0em;
}

.row > * {
    padding: 0 0 0 2em;
}

.row {
    margin: 0 0 -1px -2em;
}

.row.uniform > * {
    padding: 2em 0 0 2em;
}

.row.uniform {
    margin: -2em 0 -1px -2em;
}

.row.\32 00\25 > * {
    padding: 0 0 0 4em;
}

.row.\32 00\25 {
    margin: 0 0 -1px -4em;
}

.row.uniform.\32 00\25 > * {
    padding: 4em 0 0 4em;
}

.row.uniform.\32 00\25 {
    margin: -4em 0 -1px -4em;
}

.row.\31 50\25 > * {
    padding: 0 0 0 3em;
}

.row.\31 50\25 {
    margin: 0 0 -1px -3em;
}

.row.uniform.\31 50\25 > * {
    padding: 3em 0 0 3em;
}

.row.uniform.\31 50\25 {
    margin: -3em 0 -1px -3em;
}

.row.\35 0\25 > * {
    padding: 0 0 0 1em;
}

.row.\35 0\25 {
    margin: 0 0 -1px -1em;
}

.row.uniform.\35 0\25 > * {
    padding: 1em 0 0 1em;
}

.row.uniform.\35 0\25 {
    margin: -1em 0 -1px -1em;
}

.row.\32 5\25 > * {
    padding: 0 0 0 0.5em;
}

.row.\32 5\25 {
    margin: 0 0 -1px -0.5em;
}

.row.uniform.\32 5\25 > * {
    padding: 0.5em 0 0 0.5em;
}

.row.uniform.\32 5\25 {
    margin: -0.5em 0 -1px -0.5em;
}

.\31 2u,
.\31 2u\24 {
    width: 100%;
    clear: none;
    margin-left: 0;
}

.\31 1u,
.\31 1u\24 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
}

.\31 0u,
.\31 0u\24 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
}

.\39 u,
.\39 u\24 {
    width: 75%;
    clear: none;
    margin-left: 0;
}

.\38 u,
.\38 u\24 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
}

.\37 u,
.\37 u\24 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
}

.\36 u,
.\36 u\24 {
    width: 50%;
    clear: none;
    margin-left: 0;
}

.\35 u,
.\35 u\24 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
}

.\34 u,
.\34 u\24 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
}

.\33 u,
.\33 u\24 {
    width: 25%;
    clear: none;
    margin-left: 0;
}

.\32 u,
.\32 u\24 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
}

.\31 u,
.\31 u\24 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
}

.\31 2u\24 + *,
.\31 1u\24 + *,
.\31 0u\24 + *,
.\39 u\24 + *,
.\38 u\24 + *,
.\37 u\24 + *,
.\36 u\24 + *,
.\35 u\24 + *,
.\34 u\24 + *,
.\33 u\24 + *,
.\32 u\24 + *,
.\31 u\24 + * {
    clear: left;
}

.\-11u {
    margin-left: 91.66667%;
}

.\-10u {
    margin-left: 83.33333%;
}

.\-9u {
    margin-left: 75%;
}

.\-8u {
    margin-left: 66.66667%;
}

.\-7u {
    margin-left: 58.33333%;
}

.\-6u {
    margin-left: 50%;
}

.\-5u {
    margin-left: 41.66667%;
}

.\-4u {
    margin-left: 33.33333%;
}

.\-3u {
    margin-left: 25%;
}

.\-2u {
    margin-left: 16.66667%;
}

.\-1u {
    margin-left: 8.33333%;
}

@media screen and (max-width: 1680px) {
    .row > * {
        padding: 0 0 0 2em;
    }

    .row {
        margin: 0 0 -1px -2em;
    }

    .row.uniform > * {
        padding: 2em 0 0 2em;
    }

    .row.uniform {
        margin: -2em 0 -1px -2em;
    }

    .row.\32 00\25 > * {
        padding: 0 0 0 4em;
    }

    .row.\32 00\25 {
        margin: 0 0 -1px -4em;
    }

    .row.uniform.\32 00\25 > * {
        padding: 4em 0 0 4em;
    }

    .row.uniform.\32 00\25 {
        margin: -4em 0 -1px -4em;
    }

    .row.\31 50\25 > * {
        padding: 0 0 0 3em;
    }

    .row.\31 50\25 {
        margin: 0 0 -1px -3em;
    }

    .row.uniform.\31 50\25 > * {
        padding: 3em 0 0 3em;
    }

    .row.uniform.\31 50\25 {
        margin: -3em 0 -1px -3em;
    }

    .row.\35 0\25 > * {
        padding: 0 0 0 1em;
    }

    .row.\35 0\25 {
        margin: 0 0 -1px -1em;
    }

    .row.uniform.\35 0\25 > * {
        padding: 1em 0 0 1em;
    }

    .row.uniform.\35 0\25 {
        margin: -1em 0 -1px -1em;
    }

    .row.\32 5\25 > * {
        padding: 0 0 0 0.5em;
    }

    .row.\32 5\25 {
        margin: 0 0 -1px -0.5em;
    }

    .row.uniform.\32 5\25 > * {
        padding: 0.5em 0 0 0.5em;
    }

    .row.uniform.\32 5\25 {
        margin: -0.5em 0 -1px -0.5em;
    }

    .\31 2u\28xlarge\29,
    .\31 2u\24\28xlarge\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }

    .\31 1u\28xlarge\29,
    .\31 1u\24\28xlarge\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 0u\28xlarge\29,
    .\31 0u\24\28xlarge\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\39 u\28xlarge\29,
    .\39 u\24\28xlarge\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }

    .\38 u\28xlarge\29,
    .\38 u\24\28xlarge\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\37 u\28xlarge\29,
    .\37 u\24\28xlarge\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\36 u\28xlarge\29,
    .\36 u\24\28xlarge\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }

    .\35 u\28xlarge\29,
    .\35 u\24\28xlarge\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\34 u\28xlarge\29,
    .\34 u\24\28xlarge\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\33 u\28xlarge\29,
    .\33 u\24\28xlarge\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }

    .\32 u\28xlarge\29,
    .\32 u\24\28xlarge\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 u\28xlarge\29,
    .\31 u\24\28xlarge\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\31 2u\24\28xlarge\29 + *,
    .\31 1u\24\28xlarge\29 + *,
    .\31 0u\24\28xlarge\29 + *,
    .\39 u\24\28xlarge\29 + *,
    .\38 u\24\28xlarge\29 + *,
    .\37 u\24\28xlarge\29 + *,
    .\36 u\24\28xlarge\29 + *,
    .\35 u\24\28xlarge\29 + *,
    .\34 u\24\28xlarge\29 + *,
    .\33 u\24\28xlarge\29 + *,
    .\32 u\24\28xlarge\29 + *,
    .\31 u\24\28xlarge\29 + * {
        clear: left;
    }

    .\-11u\28xlarge\29 {
        margin-left: 91.66667%;
    }

    .\-10u\28xlarge\29 {
        margin-left: 83.33333%;
    }

    .\-9u\28xlarge\29 {
        margin-left: 75%;
    }

    .\-8u\28xlarge\29 {
        margin-left: 66.66667%;
    }

    .\-7u\28xlarge\29 {
        margin-left: 58.33333%;
    }

    .\-6u\28xlarge\29 {
        margin-left: 50%;
    }

    .\-5u\28xlarge\29 {
        margin-left: 41.66667%;
    }

    .\-4u\28xlarge\29 {
        margin-left: 33.33333%;
    }

    .\-3u\28xlarge\29 {
        margin-left: 25%;
    }

    .\-2u\28xlarge\29 {
        margin-left: 16.66667%;
    }

    .\-1u\28xlarge\29 {
        margin-left: 8.33333%;
    }
}

@media screen and (max-width: 1280px) {
    .row > * {
        padding: 0 0 0 1.5em;
    }

    .row {
        margin: 0 0 -1px -1.5em;
    }

    .row.uniform > * {
        padding: 1.5em 0 0 1.5em;
    }

    .row.uniform {
        margin: -1.5em 0 -1px -1.5em;
    }

    .row.\32 00\25 > * {
        padding: 0 0 0 3em;
    }

    .row.\32 00\25 {
        margin: 0 0 -1px -3em;
    }

    .row.uniform.\32 00\25 > * {
        padding: 3em 0 0 3em;
    }

    .row.uniform.\32 00\25 {
        margin: -3em 0 -1px -3em;
    }

    .row.\31 50\25 > * {
        padding: 0 0 0 2.25em;
    }

    .row.\31 50\25 {
        margin: 0 0 -1px -2.25em;
    }

    .row.uniform.\31 50\25 > * {
        padding: 2.25em 0 0 2.25em;
    }

    .row.uniform.\31 50\25 {
        margin: -2.25em 0 -1px -2.25em;
    }

    .row.\35 0\25 > * {
        padding: 0 0 0 0.75em;
    }

    .row.\35 0\25 {
        margin: 0 0 -1px -0.75em;
    }

    .row.uniform.\35 0\25 > * {
        padding: 0.75em 0 0 0.75em;
    }

    .row.uniform.\35 0\25 {
        margin: -0.75em 0 -1px -0.75em;
    }

    .row.\32 5\25 > * {
        padding: 0 0 0 0.375em;
    }

    .row.\32 5\25 {
        margin: 0 0 -1px -0.375em;
    }

    .row.uniform.\32 5\25 > * {
        padding: 0.375em 0 0 0.375em;
    }

    .row.uniform.\32 5\25 {
        margin: -0.375em 0 -1px -0.375em;
    }

    .\31 2u\28large\29,
    .\31 2u\24\28large\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }

    .\31 1u\28large\29,
    .\31 1u\24\28large\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 0u\28large\29,
    .\31 0u\24\28large\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\39 u\28large\29,
    .\39 u\24\28large\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }

    .\38 u\28large\29,
    .\38 u\24\28large\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\37 u\28large\29,
    .\37 u\24\28large\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\36 u\28large\29,
    .\36 u\24\28large\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }

    .\35 u\28large\29,
    .\35 u\24\28large\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\34 u\28large\29,
    .\34 u\24\28large\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\33 u\28large\29,
    .\33 u\24\28large\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }

    .\32 u\28large\29,
    .\32 u\24\28large\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 u\28large\29,
    .\31 u\24\28large\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\31 2u\24\28large\29 + *,
    .\31 1u\24\28large\29 + *,
    .\31 0u\24\28large\29 + *,
    .\39 u\24\28large\29 + *,
    .\38 u\24\28large\29 + *,
    .\37 u\24\28large\29 + *,
    .\36 u\24\28large\29 + *,
    .\35 u\24\28large\29 + *,
    .\34 u\24\28large\29 + *,
    .\33 u\24\28large\29 + *,
    .\32 u\24\28large\29 + *,
    .\31 u\24\28large\29 + * {
        clear: left;
    }

    .\-11u\28large\29 {
        margin-left: 91.66667%;
    }

    .\-10u\28large\29 {
        margin-left: 83.33333%;
    }

    .\-9u\28large\29 {
        margin-left: 75%;
    }

    .\-8u\28large\29 {
        margin-left: 66.66667%;
    }

    .\-7u\28large\29 {
        margin-left: 58.33333%;
    }

    .\-6u\28large\29 {
        margin-left: 50%;
    }

    .\-5u\28large\29 {
        margin-left: 41.66667%;
    }

    .\-4u\28large\29 {
        margin-left: 33.33333%;
    }

    .\-3u\28large\29 {
        margin-left: 25%;
    }

    .\-2u\28large\29 {
        margin-left: 16.66667%;
    }

    .\-1u\28large\29 {
        margin-left: 8.33333%;
    }
}

@media screen and (max-width: 980px) {
    .row > * {
        padding: 0 0 0 1.5em;
    }

    .row {
        margin: 0 0 -1px -1.5em;
    }

    .row.uniform > * {
        padding: 1.5em 0 0 1.5em;
    }

    .row.uniform {
        margin: -1.5em 0 -1px -1.5em;
    }

    .row.\32 00\25 > * {
        padding: 0 0 0 3em;
    }

    .row.\32 00\25 {
        margin: 0 0 -1px -3em;
    }

    .row.uniform.\32 00\25 > * {
        padding: 3em 0 0 3em;
    }

    .row.uniform.\32 00\25 {
        margin: -3em 0 -1px -3em;
    }

    .row.\31 50\25 > * {
        padding: 0 0 0 2.25em;
    }

    .row.\31 50\25 {
        margin: 0 0 -1px -2.25em;
    }

    .row.uniform.\31 50\25 > * {
        padding: 2.25em 0 0 2.25em;
    }

    .row.uniform.\31 50\25 {
        margin: -2.25em 0 -1px -2.25em;
    }

    .row.\35 0\25 > * {
        padding: 0 0 0 0.75em;
    }

    .row.\35 0\25 {
        margin: 0 0 -1px -0.75em;
    }

    .row.uniform.\35 0\25 > * {
        padding: 0.75em 0 0 0.75em;
    }

    .row.uniform.\35 0\25 {
        margin: -0.75em 0 -1px -0.75em;
    }

    .row.\32 5\25 > * {
        padding: 0 0 0 0.375em;
    }

    .row.\32 5\25 {
        margin: 0 0 -1px -0.375em;
    }

    .row.uniform.\32 5\25 > * {
        padding: 0.375em 0 0 0.375em;
    }

    .row.uniform.\32 5\25 {
        margin: -0.375em 0 -1px -0.375em;
    }

    .\31 2u\28medium\29,
    .\31 2u\24\28medium\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }

    .\31 1u\28medium\29,
    .\31 1u\24\28medium\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 0u\28medium\29,
    .\31 0u\24\28medium\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\39 u\28medium\29,
    .\39 u\24\28medium\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }

    .\38 u\28medium\29,
    .\38 u\24\28medium\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\37 u\28medium\29,
    .\37 u\24\28medium\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\36 u\28medium\29,
    .\36 u\24\28medium\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }

    .\35 u\28medium\29,
    .\35 u\24\28medium\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\34 u\28medium\29,
    .\34 u\24\28medium\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\33 u\28medium\29,
    .\33 u\24\28medium\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }

    .\32 u\28medium\29,
    .\32 u\24\28medium\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 u\28medium\29,
    .\31 u\24\28medium\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\31 2u\24\28medium\29 + *,
    .\31 1u\24\28medium\29 + *,
    .\31 0u\24\28medium\29 + *,
    .\39 u\24\28medium\29 + *,
    .\38 u\24\28medium\29 + *,
    .\37 u\24\28medium\29 + *,
    .\36 u\24\28medium\29 + *,
    .\35 u\24\28medium\29 + *,
    .\34 u\24\28medium\29 + *,
    .\33 u\24\28medium\29 + *,
    .\32 u\24\28medium\29 + *,
    .\31 u\24\28medium\29 + * {
        clear: left;
    }

    .\-11u\28medium\29 {
        margin-left: 91.66667%;
    }

    .\-10u\28medium\29 {
        margin-left: 83.33333%;
    }

    .\-9u\28medium\29 {
        margin-left: 75%;
    }

    .\-8u\28medium\29 {
        margin-left: 66.66667%;
    }

    .\-7u\28medium\29 {
        margin-left: 58.33333%;
    }

    .\-6u\28medium\29 {
        margin-left: 50%;
    }

    .\-5u\28medium\29 {
        margin-left: 41.66667%;
    }

    .\-4u\28medium\29 {
        margin-left: 33.33333%;
    }

    .\-3u\28medium\29 {
        margin-left: 25%;
    }

    .\-2u\28medium\29 {
        margin-left: 16.66667%;
    }

    .\-1u\28medium\29 {
        margin-left: 8.33333%;
    }
}

@media screen and (max-width: 736px) {
    .row > * {
        padding: 0 0 0 1.25em;
    }

    .row {
        margin: 0 0 -1px -1.25em;
    }

    .row.uniform > * {
        padding: 1.25em 0 0 1.25em;
    }

    .row.uniform {
        margin: -1.25em 0 -1px -1.25em;
    }

    .row.\32 00\25 > * {
        padding: 0 0 0 2.5em;
    }

    .row.\32 00\25 {
        margin: 0 0 -1px -2.5em;
    }

    .row.uniform.\32 00\25 > * {
        padding: 2.5em 0 0 2.5em;
    }

    .row.uniform.\32 00\25 {
        margin: -2.5em 0 -1px -2.5em;
    }

    .row.\31 50\25 > * {
        padding: 0 0 0 1.875em;
    }

    .row.\31 50\25 {
        margin: 0 0 -1px -1.875em;
    }

    .row.uniform.\31 50\25 > * {
        padding: 1.875em 0 0 1.875em;
    }

    .row.uniform.\31 50\25 {
        margin: -1.875em 0 -1px -1.875em;
    }

    .row.\35 0\25 > * {
        padding: 0 0 0 0.625em;
    }

    .row.\35 0\25 {
        margin: 0 0 -1px -0.625em;
    }

    .row.uniform.\35 0\25 > * {
        padding: 0.625em 0 0 0.625em;
    }

    .row.uniform.\35 0\25 {
        margin: -0.625em 0 -1px -0.625em;
    }

    .row.\32 5\25 > * {
        padding: 0 0 0 0.3125em;
    }

    .row.\32 5\25 {
        margin: 0 0 -1px -0.3125em;
    }

    .row.uniform.\32 5\25 > * {
        padding: 0.3125em 0 0 0.3125em;
    }

    .row.uniform.\32 5\25 {
        margin: -0.3125em 0 -1px -0.3125em;
    }

    .\31 2u\28small\29,
    .\31 2u\24\28small\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }

    .\31 1u\28small\29,
    .\31 1u\24\28small\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 0u\28small\29,
    .\31 0u\24\28small\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\39 u\28small\29,
    .\39 u\24\28small\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }

    .\38 u\28small\29,
    .\38 u\24\28small\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\37 u\28small\29,
    .\37 u\24\28small\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\36 u\28small\29,
    .\36 u\24\28small\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }

    .\35 u\28small\29,
    .\35 u\24\28small\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\34 u\28small\29,
    .\34 u\24\28small\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\33 u\28small\29,
    .\33 u\24\28small\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }

    .\32 u\28small\29,
    .\32 u\24\28small\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 u\28small\29,
    .\31 u\24\28small\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\31 2u\24\28small\29 + *,
    .\31 1u\24\28small\29 + *,
    .\31 0u\24\28small\29 + *,
    .\39 u\24\28small\29 + *,
    .\38 u\24\28small\29 + *,
    .\37 u\24\28small\29 + *,
    .\36 u\24\28small\29 + *,
    .\35 u\24\28small\29 + *,
    .\34 u\24\28small\29 + *,
    .\33 u\24\28small\29 + *,
    .\32 u\24\28small\29 + *,
    .\31 u\24\28small\29 + * {
        clear: left;
    }

    .\-11u\28small\29 {
        margin-left: 91.66667%;
    }

    .\-10u\28small\29 {
        margin-left: 83.33333%;
    }

    .\-9u\28small\29 {
        margin-left: 75%;
    }

    .\-8u\28small\29 {
        margin-left: 66.66667%;
    }

    .\-7u\28small\29 {
        margin-left: 58.33333%;
    }

    .\-6u\28small\29 {
        margin-left: 50%;
    }

    .\-5u\28small\29 {
        margin-left: 41.66667%;
    }

    .\-4u\28small\29 {
        margin-left: 33.33333%;
    }

    .\-3u\28small\29 {
        margin-left: 25%;
    }

    .\-2u\28small\29 {
        margin-left: 16.66667%;
    }

    .\-1u\28small\29 {
        margin-left: 8.33333%;
    }
}

@media screen and (max-width: 480px) {
    .row > * {
        padding: 0 0 0 1.25em;
    }

    .row {
        margin: 0 0 -1px -1.25em;
    }

    .row.uniform > * {
        padding: 1.25em 0 0 1.25em;
    }

    .row.uniform {
        margin: -1.25em 0 -1px -1.25em;
    }

    .row.\32 00\25 > * {
        padding: 0 0 0 2.5em;
    }

    .row.\32 00\25 {
        margin: 0 0 -1px -2.5em;
    }

    .row.uniform.\32 00\25 > * {
        padding: 2.5em 0 0 2.5em;
    }

    .row.uniform.\32 00\25 {
        margin: -2.5em 0 -1px -2.5em;
    }

    .row.\31 50\25 > * {
        padding: 0 0 0 1.875em;
    }

    .row.\31 50\25 {
        margin: 0 0 -1px -1.875em;
    }

    .row.uniform.\31 50\25 > * {
        padding: 1.875em 0 0 1.875em;
    }

    .row.uniform.\31 50\25 {
        margin: -1.875em 0 -1px -1.875em;
    }

    .row.\35 0\25 > * {
        padding: 0 0 0 0.625em;
    }

    .row.\35 0\25 {
        margin: 0 0 -1px -0.625em;
    }

    .row.uniform.\35 0\25 > * {
        padding: 0.625em 0 0 0.625em;
    }

    .row.uniform.\35 0\25 {
        margin: -0.625em 0 -1px -0.625em;
    }

    .row.\32 5\25 > * {
        padding: 0 0 0 0.3125em;
    }

    .row.\32 5\25 {
        margin: 0 0 -1px -0.3125em;
    }

    .row.uniform.\32 5\25 > * {
        padding: 0.3125em 0 0 0.3125em;
    }

    .row.uniform.\32 5\25 {
        margin: -0.3125em 0 -1px -0.3125em;
    }

    .\31 2u\28xsmall\29,
    .\31 2u\24\28xsmall\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }

    .\31 1u\28xsmall\29,
    .\31 1u\24\28xsmall\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 0u\28xsmall\29,
    .\31 0u\24\28xsmall\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\39 u\28xsmall\29,
    .\39 u\24\28xsmall\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }

    .\38 u\28xsmall\29,
    .\38 u\24\28xsmall\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\37 u\28xsmall\29,
    .\37 u\24\28xsmall\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\36 u\28xsmall\29,
    .\36 u\24\28xsmall\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }

    .\35 u\28xsmall\29,
    .\35 u\24\28xsmall\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\34 u\28xsmall\29,
    .\34 u\24\28xsmall\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\33 u\28xsmall\29,
    .\33 u\24\28xsmall\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }

    .\32 u\28xsmall\29,
    .\32 u\24\28xsmall\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }

    .\31 u\28xsmall\29,
    .\31 u\24\28xsmall\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }

    .\31 2u\24\28xsmall\29 + *,
    .\31 1u\24\28xsmall\29 + *,
    .\31 0u\24\28xsmall\29 + *,
    .\39 u\24\28xsmall\29 + *,
    .\38 u\24\28xsmall\29 + *,
    .\37 u\24\28xsmall\29 + *,
    .\36 u\24\28xsmall\29 + *,
    .\35 u\24\28xsmall\29 + *,
    .\34 u\24\28xsmall\29 + *,
    .\33 u\24\28xsmall\29 + *,
    .\32 u\24\28xsmall\29 + *,
    .\31 u\24\28xsmall\29 + * {
        clear: left;
    }

    .\-11u\28xsmall\29 {
        margin-left: 91.66667%;
    }

    .\-10u\28xsmall\29 {
        margin-left: 83.33333%;
    }

    .\-9u\28xsmall\29 {
        margin-left: 75%;
    }

    .\-8u\28xsmall\29 {
        margin-left: 66.66667%;
    }

    .\-7u\28xsmall\29 {
        margin-left: 58.33333%;
    }

    .\-6u\28xsmall\29 {
        margin-left: 50%;
    }

    .\-5u\28xsmall\29 {
        margin-left: 41.66667%;
    }

    .\-4u\28xsmall\29 {
        margin-left: 33.33333%;
    }

    .\-3u\28xsmall\29 {
        margin-left: 25%;
    }

    .\-2u\28xsmall\29 {
        margin-left: 16.66667%;
    }

    .\-1u\28xsmall\29 {
        margin-left: 8.33333%;
    }
}

/* Basic */

@-ms-viewport {
    width: device-width;
}

body {
    -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {
    html,
    body {
        min-width: 320px;
    }
}

body {
    background: #fff;
}

body.is-loading *,
body.is-loading *:before,
body.is-loading *:after {
    -moz-animation: none !important;
    -webkit-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    -moz-transition: none !important;
    -webkit-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
}

/* Type */

body {
    background-color: #fefefe; /*#fff*/
    color: #000000; /*#111111*/
}

body,
input,
select,
textarea {
    font-family: 'Lato', sans-serif;
    font-size: 15pt;
    font-weight: 300;
    line-height: 2;
}

@media screen and (max-width: 1680px) {
    body,
    input,
    select,
    textarea {
        font-size: 13pt;
    }
}

@media screen and (max-width: 1280px) {
    body,
    input,
    select,
    textarea {
        font-size: 13pt;
    }
}

@media screen and (max-width: 980px) {
    body,
    input,
    select,
    textarea {
        font-size: 12pt;
    }
}

@media screen and (max-width: 736px) {
    body,
    input,
    select,
    textarea {
        font-size: 12pt;
    }
}

@media screen and (max-width: 480px) {
    body,
    input,
    select,
    textarea {
        font-size: 12pt;
    }
}

a {
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

strong,
b {
    font-weight: 700;
}

em,
i {
    font-style: italic;
}

p {
    margin: 0 0 2em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 1em 0;
    text-transform: uppercase;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
    text-decoration: none;
}

h1 {
    font-size: 2em;
    margin: 0 0 0.25em 0;
}

h2 {
    font-size: 1.75em;
}

h3 {
    font-size: 1.35em;
}

h4 {
    font-size: 1.1em;
}

h5 {
    font-size: 0.9em;
}

h6 {
    font-size: 0.7em;
}

@media screen and (max-width: 736px) {
    h1 {
        font-size: 1.5em;
    }

    h2 {
        font-size: 1.25em;
    }

    h3 {
        font-size: 1.1em;
    }
}

@media screen and (max-width: 1680px) {
    h3 {
        font-size: 1.2em;
    }
}

sub {
    font-size: 0.8em;
    position: relative;
    top: 0.5em;
}

sup {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}

blockquote {
    border-left: solid 4px;
    font-style: italic;
    margin: 0 0 2em 0;
    padding: 0.5em 0 0.5em 2em;
}

code {
    border-radius: 0;
    border: solid 1px;
    font-family: 'Courier New', monospace;
    font-size: 0.9em;
    margin: 0 0.25em;
    padding: 0.25em 0.65em;
}

pre {
    -webkit-overflow-scrolling: touch;
    font-family: 'Courier New', monospace;
    font-size: 0.9em;
    margin: 0 0 2em 0;
}

pre code {
    display: block;
    line-height: 1.75;
    padding: 1em 1.5em;
    overflow-x: auto;
}

hr {
    border: 0;
    border-bottom: solid 1px;
    margin: 2em 0;
}

hr.major {
    margin: 3em 0;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

input,
select,
textarea {
    color: var(--main-red); /*#e5474b*/
}

a {
    color: var(--main-red); /*#e5474b*/
}

strong,
b {
    color: var(--main-red); /*#e5474b*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--main-red); /*#e5474b*/
}

blockquote {
    border-left-color: var(--main-red); /*#e5474b*/
}

code {
    background: none;
    border-color: var(--main-red); /*#e5474b*/
}

hr {
    border-bottom-color: var(--main-red); /*#e5474b*/
}

/* Box */

.box {
    border-radius: 0;
    border: solid 1px;
    margin-bottom: 2em;
    padding: 1.5em;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
    margin-bottom: 0;
}

.box.alt {
    border: 0;
    border-radius: 0;
    padding: 0;
}

.box {
    border-color: var(--main-red); /*#e5474b*/
}

/* Button */

input[type='submit'],
input[type='reset'],
input[type='button'],
button,
.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out,
        color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    border-radius: 0;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 0.8em;
    font-weight: 700;
    height: 3.5em;
    line-height: 3.65em;
    padding: 0 2.25em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}

input[type='submit']:hover,
input[type='reset']:hover,
input[type='button']:hover,
button:hover,
.button:hover {
    text-decoration: none;
}

input[type='submit'].icon,
input[type='reset'].icon,
input[type='button'].icon,
button.icon,
.button.icon {
    padding-left: 1.35em;
}

input[type='submit'].icon:before,
input[type='reset'].icon:before,
input[type='button'].icon:before,
button.icon:before,
.button.icon:before {
    margin-right: 0.5em;
}

input[type='submit'].fit,
input[type='reset'].fit,
input[type='button'].fit,
button.fit,
.button.fit {
    display: block;
    margin: 0 0 1em 0;
    width: 100%;
}

input[type='submit'].small,
input[type='reset'].small,
input[type='button'].small,
button.small,
.button.small {
    font-size: 0.8em;
}

input[type='submit'].big,
input[type='reset'].big,
input[type='button'].big,
button.big,
.button.big {
    font-size: 1.35em;
}

input[type='submit'].disabled,
input[type='submit']:disabled,
input[type='reset'].disabled,
input[type='reset']:disabled,
input[type='button'].disabled,
input[type='button']:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
    -moz-pointer-events: none;
    -webkit-pointer-events: none;
    -ms-pointer-events: none;
    pointer-events: none;
    opacity: 0.25;
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button,
.button {
    background-color: #666666; /*#5a5a5a*/
    color: #fefefe !important; /*#ffffff*/
}

input[type='submit']:hover,
input[type='reset']:hover,
input[type='button']:hover,
button:hover,
.button:hover {
    background-color: var(--main-red); /*#676767*/
}

input[type='submit']:active,
input[type='reset']:active,
input[type='button']:active,
button:active,
.button:active {
    background-color: #333333; /*#4d4d4d*/
}

input[type='submit'].alt,
input[type='reset'].alt,
input[type='button'].alt,
button.alt,
.button.alt {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--main-red); /*#e5474b*/
    color: var(--main-red) !important; /*#e5474b*/
}

input[type='submit'].alt:hover,
input[type='reset'].alt:hover,
input[type='button'].alt:hover,
button.alt:hover,
.button.alt:hover {
    background: #fceced; /*check this!!!!!!!!!!!!!!!!!!!!*/
}

input[type='submit'].alt:active,
input[type='reset'].alt:active,
input[type='button'].alt:active,
button.alt:active,
.button.alt:active {
    background-color: #f9dadb; /*check this!!!!!!!!!!!!!!!!!!!!*/
}

input[type='submit'].alt.icon:before,
input[type='reset'].alt.icon:before,
input[type='button'].alt.icon:before,
button.alt.icon:before,
.button.alt.icon:before {
    color: #717171; /*check this!!!!!!!!!!!!!!!!!!!!*/
}

input[type='submit'].special,
input[type='reset'].special,
input[type='button'].special,
button.special,
.button.special {
    background-color: #e5474b; /*check this!!!!!!!!!!!!!!!!!!!!*/
    color: #ffffff !important; /*check this!!!!!!!!!!!!!!!!!!!!*/
}

input[type='submit'].special:hover,
input[type='reset'].special:hover,
input[type='button'].special:hover,
button.special:hover,
.button.special:hover {
    background-color: #e85d61; /*check this!!!!!!!!!!!!!!!!!!!!*/
}

input[type='submit'].special:active,
input[type='reset'].special:active,
input[type='button'].special:active,
button.special:active,
.button.special:active {
    background-color: #e23135; /*check this!!!!!!!!!!!!!!!!!!!!*/
}

/* Form */

form {
    margin: 0 0 2em 0;
}

form .field {
    margin: 0 0 2em 0;
}

form .field.half {
    width: 50%;
    float: left;
    padding: 0 0 0 1em;
}

form .field.half.first {
    padding: 0 1em 0 0;
}

form > .actions {
    margin: 2.5em 0 0 0 !important;
}

@media screen and (max-width: 736px) {
    form .field {
        margin: 0 0 1.5em 0;
    }

    form .field.half {
        padding: 0 0 0 0.75em;
    }

    form .field.half.first {
        padding: 0 0.75em 0 0;
    }

    form > .actions {
        margin: 2em 0 0 0 !important;
    }
}

@media screen and (max-width: 480px) {
    form .field.half {
        width: 100%;
        float: none;
        padding: 0;
    }

    form .field.half.first {
        padding: 0;
    }
}

label {
    display: block;
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 1em 0;
}

input[type='text'],
input[type='password'],
input[type='email'],
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 0;
    border: none;
    border: solid 3px;
    color: inherit;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;
}

input[type='text']:invalid,
input[type='password']:invalid,
input[type='email']:invalid,
select:invalid,
textarea:invalid {
    box-shadow: none;
}

.select-wrapper {
    text-decoration: none;
    display: block;
    position: relative;
}

.select-wrapper:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
}

.select-wrapper:before {
    content: '\f078';
    display: block;
    height: 2.75em;
    line-height: 2.75em;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 2.75em;
}

.select-wrapper select::-ms-expand {
    display: none;
}

input[type='text'],
input[type='password'],
input[type='email'],
select {
    height: 2.75em;
}

textarea {
    padding: 0.75em 1em;
}

input[type='checkbox'],
input[type='radio'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    display: block;
    float: left;
    margin-right: -2em;
    opacity: 0;
    width: 1em;
    z-index: -1;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 300;
    padding-left: 2.4em;
    padding-right: 0.75em;
    position: relative;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
    border-radius: 0;
    border: solid 1px;
    content: '';
    display: inline-block;
    height: 1.65em;
    left: 0;
    line-height: 1.58125em;
    position: absolute;
    text-align: center;
    top: 0;
    width: 1.65em;
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
    content: '\f00c';
}

input[type='checkbox'] + label:before {
    border-radius: 0;
}

input[type='radio'] + label:before {
    border-radius: 100%;
}

::-webkit-input-placeholder {
    opacity: 1;
}

:-moz-placeholder {
    opacity: 1;
}

::-moz-placeholder {
    opacity: 1;
}

:-ms-input-placeholder {
    opacity: 1;
}

.formerize-placeholder {
    opacity: 1;
}

label {
    color: var(--main-red); /*#e5474b*/
}

input[type='text'],
input[type='password'],
input[type='email'],
select,
textarea {
    background: none;
    border-color: #fefefe; /*#fceced*/
}

input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
select:focus,
textarea:focus {
    border-color: var(--main-red); /*#e5474b*/
    box-shadow: 0 0 0 1px var(--main-red); /*#e5474b*/
}

.select-wrapper:before {
    color: var(--main-red); /*#e5474b*/
}

input[type='checkbox'] + label,
input[type='radio'] + label {
    color: #000000; /*#111111*/
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
    background: none;
    border-color: var(--main-red); /*#e5474b*/
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
    background-color: var(--main-red); /*#e5474b*/
    border-color: var(--main-red); /*#e5474b*/
    color: #fefefe; /*#ffffff*/
}

input[type='checkbox']:focus + label:before,
input[type='radio']:focus + label:before {
    border-color: var(--main-red); /*#e5474b*/
    box-shadow: 0 0 0 1px var(--main-red); /*#e5474b*/
}

::-webkit-input-placeholder {
    color: #666666 !important; /*#717171*/
}

:-moz-placeholder {
    color: #666666 !important; /*#717171*/
}

::-moz-placeholder {
    color: #666666 !important; /*#717171*/
}

:-ms-input-placeholder {
    color: #666666 !important; /*#717171*/
}

.formerize-placeholder {
    color: #666666 !important; /*#717171*/
}

/* Icon */

.icon {
    text-decoration: none;
    border-bottom: none;
    position: relative;
}

.icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
}

.icon > .label {
    display: none;
}

/* Image */

.image {
    border-radius: 0;
    border: 0;
    display: inline-block;
    position: relative;
}

.image img {
    border-radius: 0;
    display: block;
}

.image.left,
.image.right {
    max-width: 40%;
}

.image.left img,
.image.right img {
    width: 100%;
}

.image.left {
    float: left;
    margin: 0 1.5em 1em 0;
    top: 0.25em;
}

.image.right {
    float: right;
    margin: 0 0 1em 1.5em;
    top: 0.25em;
}

.image.main {
    display: block;
    margin: 0 0 3em 0;
    width: 100%;
}

.image.main img {
    width: 100%;
}

/* List */

ol {
    list-style: decimal;
    margin: 0 0 2em 0;
    padding-left: 1.25em;
}

ol li {
    padding-left: 0.25em;
}

ul {
    list-style: disc;
    margin: 0 0 2em 0;
    padding-left: 1em;
}

ul li {
    padding-left: 0.5em;
}

ul.alt {
    list-style: none;
    padding-left: 0;
}

ul.alt li {
    border-top: solid 1px;
    padding: 0.5em 0;
}

ul.alt li:first-child {
    border-top: 0;
    padding-top: 0;
}

ul.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;
}

ul.icons li {
    display: inline-block;
    padding: 0 1em 0 0;
}

ul.icons li:last-child {
    padding-right: 0;
}

ul.icons li .icon:before {
    font-size: 2em;
}

ul.actions {
    cursor: default;
    list-style: none;
    padding-left: 0;
}

ul.actions li {
    display: inline-block;
    padding: 0 1em 0 0;
    vertical-align: middle;
}

ul.actions li:last-child {
    padding-right: 0;
}

ul.actions.small li {
    padding: 0 0.5em 0 0;
}

ul.actions.vertical li {
    display: block;
    padding: 1em 0 0 0;
}

ul.actions.vertical li:first-child {
    padding-top: 0;
}

ul.actions.vertical li > * {
    margin-bottom: 0;
}

ul.actions.vertical.small li {
    padding: 0.5em 0 0 0;
}

ul.actions.vertical.small li:first-child {
    padding-top: 0;
}

ul.actions.fit {
    display: table;
    margin-left: -1em;
    padding: 0;
    table-layout: fixed;
    width: calc(100% + 1em);
}

ul.actions.fit li {
    display: table-cell;
    padding: 0 0 0 1em;
}

ul.actions.fit li > * {
    margin-bottom: 0;
}

ul.actions.fit.small {
    margin-left: -0.5em;
    width: calc(100% + 0.5em);
}

ul.actions.fit.small li {
    padding: 0 0 0 0.5em;
}

dl {
    margin: 0 0 2em 0;
}

dl dt {
    display: block;
    font-weight: 700;
    margin: 0 0 1em 0;
}

dl dd {
    margin-left: 2em;
}

ul.alt li {
    border-top-color: var(--main-red); /*#e5474b*/
}

/* Section/Article */

section.special,
article.special {
    text-align: center;
}

section .inner {
    max-width: 65em;
    width: calc(100% - 6em);
    margin: 0 auto;
}

@media screen and (max-width: 480px) {
    section .inner {
        max-width: 90%;
        width: 90%;
    }
}

header p {
    position: relative;
    margin: 0 0 1.5em 0;
}

header h2 + p {
    font-size: 1.25em;
    margin-top: -1em;
}

header h3 + p {
    font-size: 1.1em;
    margin-top: -0.8em;
}

header h4 + p,
header h5 + p,
header h6 + p {
    font-size: 0.9em;
    margin-top: -0.6em;
}

header p {
    color: #666666; /*#717171*/
}

/* Table */

.table-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
}

table {
    margin: 0 0 2em 0;
    width: 100%;
}

table tbody tr {
    border: solid 1px;
    border-left: 0;
    border-right: 0;
}

table td {
    padding: 0.75em 0.75em;
}

table th {
    font-size: 0.9em;
    font-weight: 700;
    padding: 0 0.75em 0.75em 0.75em;
    text-align: left;
}

table thead {
    border-bottom: solid 2px;
}

table tfoot {
    border-top: solid 2px;
}

table.alt {
    border-collapse: separate;
}

table.alt tbody tr td {
    border: solid 1px;
    border-left-width: 0;
    border-top-width: 0;
}

table.alt tbody tr td:first-child {
    border-left-width: 1px;
}

table.alt tbody tr:first-child td {
    border-top-width: 1px;
}

table.alt thead {
    border-bottom: 0;
}

table.alt tfoot {
    border-top: 0;
}

table tbody tr {
    border-color: var(--main-red); /*#e5474b*/
}

table tbody tr:nth-child(2n + 1) {
    background-color: none;
}

table th {
    color: var(--main-red); /*#e5474b*/
}

table thead {
    border-bottom-color: var(--main-red); /*#e5474b*/
}

table tfoot {
    border-top-color: var(--main-red); /*#e5474b*/
}

table.alt tbody tr td {
    border-color: var(--main-red); /*#e5474b*/
}

/* Wrapper */

.wrapper {
    position: relative;
}

.wrapper > .inner {
    margin: 0 auto;
    width: 60em;
}

.wrapper.style1 {
    background-color: var(--main-red); /*#e5474b*/
    color: #f2a3a5; /* check this !!!!!!!!!!!!!!!!!!!!!!*/
}

.wrapper.style1 input,
.wrapper.style1 select,
.wrapper.style1 textarea {
    color: #fefefe; /*#ffffff*/
}

.wrapper.style1 a {
    color: #fefefe; /*#ffffff*/
}

.wrapper.style1 strong,
.wrapper.style1 b {
    color: #fefefe; /*#ffffff*/
}

.wrapper.style1 h1,
.wrapper.style1 h2,
.wrapper.style1 h3,
.wrapper.style1 h4,
.wrapper.style1 h5,
.wrapper.style1 h6 {
    color: #fefefe; /*#fff*/
}

.wrapper.style1 blockquote {
    border-left-color: #fefefe; /*#fff*/
}

.wrapper.style1 code {
    background: none;
    border-color: #fefefe; /*#fff*/
}

.wrapper.style1 hr {
    border-bottom-color: #fefefe; /*#fff*/
}

.wrapper.style1 .box {
    border-color: #fefefe; /*#fff*/
}

.wrapper.style1 input[type='submit'],
.wrapper.style1 input[type='reset'],
.wrapper.style1 input[type='button'],
.wrapper.style1 button,
.wrapper.style1 .button {
    background-color: #666666; /*#5a5a5a*/
    color: #ffffff !important;
}

.wrapper.style1 input[type='submit']:hover,
.wrapper.style1 input[type='reset']:hover,
.wrapper.style1 input[type='button']:hover,
.wrapper.style1 button:hover,
.wrapper.style1 .button:hover {
    background-color: var(--main-red); /*#676767*/
}

.wrapper.style1 input[type='submit']:active,
.wrapper.style1 input[type='reset']:active,
.wrapper.style1 input[type='button']:active,
.wrapper.style1 button:active,
.wrapper.style1 .button:active {
    background-color: #333333; /*4d4d4d*/
}

.wrapper.style1 input[type='submit'].alt,
.wrapper.style1 input[type='reset'].alt,
.wrapper.style1 input[type='button'].alt,
.wrapper.style1 button.alt,
.wrapper.style1 .button.alt {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #fff; /* check this !!!!!!!!!!!!!!!!*/
    color: #ffffff !important; /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style1 input[type='submit'].alt:hover,
.wrapper.style1 input[type='reset'].alt:hover,
.wrapper.style1 input[type='button'].alt:hover,
.wrapper.style1 button.alt:hover,
.wrapper.style1 .button.alt:hover {
    background: rgba(255, 255, 255, 0.25); /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style1 input[type='submit'].alt:active,
.wrapper.style1 input[type='reset'].alt:active,
.wrapper.style1 input[type='button'].alt:active,
.wrapper.style1 button.alt:active,
.wrapper.style1 .button.alt:active {
    background-color: rgba(255, 255, 255, 0.2); /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style1 input[type='submit'].alt.icon:before,
.wrapper.style1 input[type='reset'].alt.icon:before,
.wrapper.style1 input[type='button'].alt.icon:before,
.wrapper.style1 button.alt.icon:before,
.wrapper.style1 .button.alt.icon:before {
    color: #f8d1d2; /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style1 input[type='submit'].special,
.wrapper.style1 input[type='reset'].special,
.wrapper.style1 input[type='button'].special,
.wrapper.style1 button.special,
.wrapper.style1 .button.special {
    background-color: #ffffff; /* check this !!!!!!!!!!!!!!!!*/
    color: #e5474b !important; /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style1 label {
    color: #fefefe; /*#ffffff*/
}

.wrapper.style1 input[type='text'],
.wrapper.style1 input[type='password'],
.wrapper.style1 input[type='email'],
.wrapper.style1 select,
.wrapper.style1 textarea {
    background: none;
    border-color: #666666; /*rgba(255, 255, 255, 0.25)*/
}

.wrapper.style1 input[type='text']:focus,
.wrapper.style1 input[type='password']:focus,
.wrapper.style1 input[type='email']:focus,
.wrapper.style1 select:focus,
.wrapper.style1 textarea:focus {
    border-color: #fefefe; /*#ffffff*/
    box-shadow: 0 0 0 1px #fefefe; /*#ffffff*/
}

.wrapper.style1 .select-wrapper:before {
    color: #fefefe; /*#fff*/
}

.wrapper.style1 input[type='checkbox'] + label,
.wrapper.style1 input[type='radio'] + label {
    color: var(--main-red); /*#f2a3a5*/
}

.wrapper.style1 input[type='checkbox'] + label:before,
.wrapper.style1 input[type='radio'] + label:before {
    background: none;
    border-color: #fefefe; /*#fff*/
}

.wrapper.style1 input[type='checkbox']:checked + label:before,
.wrapper.style1 input[type='radio']:checked + label:before {
    background-color: #fefefe; /*#ffffff*/
    border-color: #fefefe; /*#ffffff*/
    color: var(--main-red); /*#e5474b*/
}

.wrapper.style1 input[type='checkbox']:focus + label:before,
.wrapper.style1 input[type='radio']:focus + label:before {
    border-color: #fefefe; /*#ffffff*/
    box-shadow: 0 0 0 1px #fefefe; /*#ffffff*/
}

.wrapper.style1 ::-webkit-input-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

.wrapper.style1 :-moz-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

.wrapper.style1 ::-moz-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

.wrapper.style1 :-ms-input-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

.wrapper.style1 .formerize-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

.wrapper.style1 ul.alt li {
    border-top-color: #fefefe; /*#fff*/
}

.wrapper.style1 header p {
    color: #fefefe; /*#f8d1d2*/
}

.wrapper.style1 table tbody tr {
    border-color: #fefefe; /*#fff*/
}

.wrapper.style1 table tbody tr:nth-child(2n + 1) {
    background-color: none;
}

.wrapper.style1 table th {
    color: #fefefe; /*#ffffff*/
}

.wrapper.style1 table thead {
    border-bottom-color: #fefefe; /*#fff*/
}

.wrapper.style1 table tfoot {
    border-top-color: #fefefe; /*#fff*/
}

.wrapper.style1 table.alt tbody tr td {
    border-color: #fefefe; /*#fff*/
}

.wrapper.style2 {
    background-color: #666666; /*#5a5a5a*/
    color: #fefefe; /*#d5d5d5*/
}

.wrapper.style2 input,
.wrapper.style2 select,
.wrapper.style2 textarea {
    color: #fefefe; /*#ffffff*/
}

.wrapper.style2 a {
    color: var(--main-red); /*#e5474b*/
}

.wrapper.style2 strong,
.wrapper.style2 b {
    color: #fefefe; /*#ffffff*/
}

.wrapper.style2 blockquote {
    border-left-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 code {
    background: #333333; /*rgba(255, 255, 255, 0.075)*/
    border-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 hr {
    border-bottom-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 .box {
    border-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 input[type='submit'],
.wrapper.style2 input[type='reset'],
.wrapper.style2 input[type='button'],
.wrapper.style2 button,
.wrapper.style2 .button {
    background-color: #fefefe; /*#ffffff*/
    color: #666666 !important; /*#5a5a5a*/
}

.wrapper.style2 input[type='submit'].alt,
.wrapper.style2 input[type='reset'].alt,
.wrapper.style2 input[type='button'].alt,
.wrapper.style2 button.alt,
.wrapper.style2 .button.alt {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #666666; /*rgba(255, 255, 255, 0.15)*/
    color: #fefefe !important; /*#ffffff*/
}

.wrapper.style2 input[type='submit'].alt:hover,
.wrapper.style2 input[type='reset'].alt:hover,
.wrapper.style2 input[type='button'].alt:hover,
.wrapper.style2 button.alt:hover,
.wrapper.style2 .button.alt:hover {
    background: rgba(255, 255, 255, 0.5); /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style2 input[type='submit'].alt:active,
.wrapper.style2 input[type='reset'].alt:active,
.wrapper.style2 input[type='button'].alt:active,
.wrapper.style2 button.alt:active,
.wrapper.style2 .button.alt:active {
    background-color: rgba(255, 255, 255, 0.2); /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style2 input[type='submit'].alt.icon:before,
.wrapper.style2 input[type='reset'].alt.icon:before,
.wrapper.style2 input[type='button'].alt.icon:before,
.wrapper.style2 button.alt.icon:before,
.wrapper.style2 .button.alt.icon:before {
    color: #bdbdbd; /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style2 input[type='submit'].special,
.wrapper.style2 input[type='reset'].special,
.wrapper.style2 input[type='button'].special,
.wrapper.style2 button.special,
.wrapper.style2 .button.special {
    background-color: #e5474b; /* check this !!!!!!!!!!!!!!!!*/
    color: #ffffff !important; /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style2 input[type='submit'].special:hover,
.wrapper.style2 input[type='reset'].special:hover,
.wrapper.style2 input[type='button'].special:hover,
.wrapper.style2 button.special:hover,
.wrapper.style2 .button.special:hover {
    background-color: #e85d61; /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style2 input[type='submit'].special:active,
.wrapper.style2 input[type='reset'].special:active,
.wrapper.style2 input[type='button'].special:active,
.wrapper.style2 button.special:active,
.wrapper.style2 .button.special:active {
    background-color: #e23135; /* check this !!!!!!!!!!!!!!!!*/
}

.wrapper.style2 label {
    color: #fefefe; /*#ffffff*/
}

.wrapper.style2 input[type='text'],
.wrapper.style2 input[type='password'],
.wrapper.style2 input[type='email'],
.wrapper.style2 select,
.wrapper.style2 textarea {
    background: #333333; /*rgba(255, 255, 255, 0.075)*/
    border-color: #666666; /*rgba(255, 255, 255, 0.5)*/
}

.wrapper.style2 input[type='text']:focus,
.wrapper.style2 input[type='password']:focus,
.wrapper.style2 input[type='email']:focus,
.wrapper.style2 select:focus,
.wrapper.style2 textarea:focus {
    border-color: var(--main-red); /*#e5474b*/
    box-shadow: 0 0 0 1px var(--main-red); /*#e5474b*/
}

.wrapper.style2 .select-wrapper:before {
    color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 input[type='checkbox'] + label,
.wrapper.style2 input[type='radio'] + label {
    color: #fefefe; /*#d5d5d5*/
}

.wrapper.style2 input[type='checkbox'] + label:before,
.wrapper.style2 input[type='radio'] + label:before {
    background: #333333; /*rgba(255, 255, 255, 0.075)*/
    border-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 input[type='checkbox']:checked + label:before,
.wrapper.style2 input[type='radio']:checked + label:before {
    background-color: var(--main-red); /*#e5474b*/
    border-color: var(--main-red); /*#e5474b*/
    color: #fefefe; /*#ffffff*/
}

.wrapper.style2 input[type='checkbox']:focus + label:before,
.wrapper.style2 input[type='radio']:focus + label:before {
    border-color: var(--main-red); /*#e5474b*/
    box-shadow: 0 0 0 1px var(--main-red); /*#e5474b*/
}

.wrapper.style2 ::-webkit-input-placeholder {
    color: #fefefe !important; /*#bdbdbd*/
}

.wrapper.style2 :-moz-placeholder {
    color: #fefefe !important; /*#bdbdbd*/
}

.wrapper.style2 ::-moz-placeholder {
    color: #fefefe !important; /*#bdbdbd*/
}

.wrapper.style2 :-ms-input-placeholder {
    color: #fefefe !important; /*#bdbdbd*/
}

.wrapper.style2 .formerize-placeholder {
    color: #fefefe !important; /*#bdbdbd*/
}

.wrapper.style2 ul.alt li {
    border-top-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 header p {
    color: #fefefe; /*#bdbdbd*/
}

.wrapper.style2 table tbody tr {
    border-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 table tbody tr:nth-child(2n + 1) {
    background-color: #333333; /*rgba(255, 255, 255, 0.075)*/
}

.wrapper.style2 table th {
    color: #fefefe; /*#ffffff*/
}

.wrapper.style2 table thead {
    border-bottom-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 table tfoot {
    border-top-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

.wrapper.style2 table.alt tbody tr td {
    border-color: #666666; /*rgba(255, 255, 255, 0.15)*/
}

@media screen and (max-width: 1280px) {
    .wrapper > .inner {
        width: 65em;
    }
}

@media screen and (max-width: 980px) {
    .wrapper > .inner {
        width: 100%;
    }
}

/* Header */

body {
    position: relative;
    padding-top: 5em;
}

#header {
    background: var(--off-black); /*#1c1c1c*/
    color: #fefefe; /*#d5d5d5*/
    cursor: default;
    height: 5em;
    left: 0;
    line-height: 5em;
    position: fixed;
    text-align: right;
    top: 0;
    width: 100%;
    z-index: 5;
}

#header .inner {
    height: 100%;
    max-width: 65em;
    width: calc(100% - 6em);
    margin: 0 auto;
    position: relative;
}

@media screen and (max-width: 480px) {
    #header .inner {
        max-width: 90%;
        width: 90%;
    }
}

#header .logo {
    font-family: 'Pacifico', cursive;
    display: inline-block;
    height: inherit;
    left: 0;
    line-height: inherit;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    color: var(--main-red); /*#e5474b*/
    font-size: 1.75em;
    text-transform: none;
    font-weight: normal;
    padding: 0;
}

#header .logo:hover {
    color: var(--main-red); /*rgba(229, 71, 75, 0.75)*/
}

#header a {
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    display: inline-block;
    padding: 0 2em;
    color: var(--off-white);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.85em;
}

#header a:hover {
    color: var(
        --light-grey
    ); /* rgba(255, 255, 255, 0.75); /* check this !!!!!!!!!!!!!!!!!*/
}

#header a:last-child {
    padding-right: 0em;
}

#header a.active {
    color: var(--alt-red);
}

@media screen and (max-width: 736px) {
    #header a {
        padding: 0 0.5em;
    }
}

@media screen and (max-width: 480px) {
    #header {
        min-width: 320px;
    }
}

/* Menu */

#nav {
    display: inline-block;
}

@media screen and (max-width: 980px) {
    #nav {
        display: none;
    }
}

.navPanelToggle {
    position: fixed;
    text-decoration: none;
    height: 4em;
    right: 3em;
    top: 0.75em;
    width: 4em;
    display: none;
    z-index: 10002;
}

.navPanelToggle:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
    color: #fefefe; /*#fff*/
    display: block;
    font-size: 16px;
    height: 2.25em;
    left: 0.5em;
    line-height: 2.25em;
    position: absolute;
    text-align: center;
    top: 0.5em;
    width: 3.5em;
}

@media screen and (max-width: 980px) {
    .navPanelToggle {
        display: block;
    }
}

@media screen and (max-width: 736px) {
    .navPanelToggle {
        right: 1em;
    }
}

.navPanelToggle *:before {
    color: #fefefe; /*#fff*/
    display: block;
    font-size: 16px;
    height: 2.25em;
    left: 0.5em;
    line-height: 2.25em;
    position: absolute;
    text-align: center;
    top: 0.5em;
    width: 3.5em;
}

#navPanel {
    -moz-transform: translatex(20em);
    -webkit-transform: translatex(20em);
    -ms-transform: translatex(20em);
    transform: translatex(20em);
    -moz-transition: -moz-transform 0.2s ease-in-out,
        visibility 0.2s ease-in-out;
    -webkit-transition: -webkit-transform 0.2s ease-in-out,
        visibility 0.2s ease-in-out;
    -ms-transition: -ms-transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    background: var(--off-black); /*#1c1c1c*/
    color: #fefefe; /*#fff*/
    height: 100%;
    max-width: 80%;
    width: 20em;
    padding: 1.5em;
    text-transform: uppercase;
    z-index: 10003;
    display: none;
}

#navPanel.visible {
    -moz-transform: translatex(0);
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0);
    box-shadow: 0 0 1.5em 0 #000000; /*rgba(0, 0, 0, 0.2)*/
    visibility: visible;
}

#navPanel a:not(.close) {
    border-top: solid 1px #666666; /*rgba(255, 255, 255, 0.1)*/
    color: #fefefe; /*#fff*/
    font-weight: 700;
    display: block;
    padding: 0.75em 0;
    text-decoration: none;
}

#navPanel a:not(.close):first-child {
    border: none;
}

#navPanel .close {
    text-decoration: none;
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    -webkit-tap-highlight-color: transparent;
    border: 0;
    color: #666666; /*#484848*/
    cursor: pointer;
    display: block;
    height: 4em;
    padding-right: 1.25em;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    width: 5em;
}

#navPanel .close:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
    content: '\f00d';
    width: 3em;
    height: 3em;
    line-height: 3em;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
}

#navPanel .close:hover {
    color: inherit;
}

@media screen and (max-width: 980px) {
    #navPanel {
        display: block;
    }
}

/* Banner */

#banner {
    background-color: var(--off-white); /*#e5474b*/
    color: rgba(26, 26, 26); /*#f2a3a5*/
    padding: 13em 0 20em 0;
    text-align: left;
    position: relative;
    z-index: 2;
    background-image: url('../assets/images/banner-sm.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

#banner input,
#banner select,
#banner textarea {
    color: #fefefe; /*#ffffff*/
}

#banner a {
    color: #fefefe; /*#ffffff*/
}

#banner strong,
#banner b {
    color: #fefefe; /*#ffffff*/
}

#banner h1,
#banner h2,
#banner h3,
#banner h4,
#banner h5,
#banner h6 {
    color: white;
}

#banner blockquote {
    border-left-color: #fefefe; /*#fff*/
}

#banner code {
    background: none;
    border-color: #fefefe; /*#fff*/
}

#banner hr {
    border-bottom-color: #fefefe; /*#fff*/
}

#banner input[type='submit'],
#banner input[type='reset'],
#banner input[type='button'],
#banner button,
#banner .button {
    background-color: #666666; /*5a5a5a*/
    color: #fefefe !important; /*#ffffff*/
}

#banner input[type='submit']:hover,
#banner input[type='reset']:hover,
#banner input[type='button']:hover,
#banner button:hover,
#banner .button:hover {
    background-color: var(--main-red); /*#676767*/
}

#banner input[type='submit']:active,
#banner input[type='reset']:active,
#banner input[type='button']:active,
#banner button:active,
#banner .button:active {
    background-color: #333333; /*#4d4d4d*/
}

#banner input[type='submit'].alt,
#banner input[type='reset'].alt,
#banner input[type='button'].alt,
#banner button.alt,
#banner .button.alt {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #fff; /* check this !!!!!!!!!!!!!!!*/
    color: #ffffff !important; /* check this !!!!!!!!!!!!!!!*/
}

#banner input[type='submit'].alt:hover,
#banner input[type='reset'].alt:hover,
#banner input[type='button'].alt:hover,
#banner button.alt:hover,
#banner .button.alt:hover {
    background: rgba(255, 255, 255, 0.25); /* check this !!!!!!!!!!!!!!!*/
}

#banner input[type='submit'].alt:active,
#banner input[type='reset'].alt:active,
#banner input[type='button'].alt:active,
#banner button.alt:active,
#banner .button.alt:active {
    background-color: rgba(255, 255, 255, 0.2); /* check this !!!!!!!!!!!!!!!*/
}

#banner input[type='submit'].alt.icon:before,
#banner input[type='reset'].alt.icon:before,
#banner input[type='button'].alt.icon:before,
#banner button.alt.icon:before,
#banner .button.alt.icon:before {
    color: #f8d1d2; /* check this !!!!!!!!!!!!!!!*/
}

#banner input[type='submit'].special,
#banner input[type='reset'].special,
#banner input[type='button'].special,
#banner button.special,
#banner .button.special {
    background-color: #ffffff; /* check this !!!!!!!!!!!!!!!*/
    color: #e5474b !important; /* check this !!!!!!!!!!!!!!!*/
}

#banner:after {
    -moz-transition: opacity 4s ease;
    -webkit-transition: opacity 4s ease;
    -ms-transition: opacity 4s ease;
    transition: opacity 4s ease;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    background-color: var(--off-black); /*#0c0c0c*/
    opacity: 0.25;
}

#banner .inner {
    max-width: 65em;
    width: calc(100% - 6em);
    margin: 0 auto;
    position: relative;
    z-index: 10000;
    line-height: 1.5;
    padding: 20px;
}

@media screen and (max-width: 480px) {
    #banner .inner {
        max-width: 90%;
        width: 90%;
    }
}

#banner h1 {
    font-size: 2em;
    margin: 0 0 1em 0;
    padding: 0;
    letter-spacing: 3px;
    font-weight: 700;
}

#banner h1 span {
    font-weight: 400;
}

body.is-loading #banner:after {
    opacity: 1;
}

@media screen and (max-width: 1680px) {
    #banner {
        padding: 10em 0 8em 0;
    }
}

@media screen and (max-width: 1280px) {
    #banner {
        padding: 8em 0 6em 0;
    }
}

@media screen and (max-width: 980px) {
    #banner {
        padding: 12em 0 10em 0;
    }

    #banner br {
        display: none;
    }
}

@media screen and (max-width: 736px) {
    #banner {
        padding: 4em 0 2em 0;
    }

    #banner h1 {
        font-size: 1.75em;
    }
}

@media screen and (max-width: 480px) {
    #banner {
        padding: 5em 0 3em 0;
    }

    #banner ul {
        margin-top: 1em;
    }
}

/* Main */

#main {
    padding: 4em 0 2em 0;
}

#one {
    padding: 6em 0 4em 0;
}

@media screen and (max-width: 980px) {
    #one {
        padding: 4em 0 2em 0;
    }
}

@media screen and (max-width: 736px) {
    #one {
        padding: 2em 0 0.1em 0;
    }
}

#examples {
    /* border-top: 5px solid var(--main-red); */
    position: relative;
    background: #f3f3f3;
}

#examples .inner {
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media screen and (max-width: 980px) {
    #examples .inner {
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    #examples .inner article {
        width: 100%;
    }
}

#three {
    padding: 6em 0 4em 0;
}

#three .inner {
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify;
}

#three .inner article {
    width: 26%;
    display: block;
}

#three .inner article p {
    color: #333333; /*#717171*/
}

#three .inner article .icon {
    color: #666666; /*#b3b3b3*/
    font-size: 3em;
}

@media screen and (max-width: 980px) {
    #three .inner {
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    #three .inner article {
        width: 100%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 980px) {
    #three {
        padding: 4em 0 2em 0;
    }
}

@media screen and (max-width: 736px) {
    #three {
        padding: 2em 0 0.1em 0;
    }
}

/* Footer */

#footer {
    color: #000000; /*#f2a3a5*/
    padding: 1em 0 2em 0;
    background: rgba(26, 26, 26); /*#e5474b*/
}

#footer input,
#footer select,
#footer textarea {
    color: #fefefe; /*#ffffff*/
}

#footer a {
    color: #fefefe; /*#ffffff*/
}

#footer strong,
#footer b {
    color: #fefefe; /*#ffffff*/
}

#footer h1,
#footer h2,
#footer h3,
#footer h4,
#footer h5,
#footer h6 {
    color: #fefefe; /*#fff*/
}

#footer blockquote {
    border-left-color: #fefefe; /*#fff*/
}

#footer code {
    background: none;
    border-color: #fefefe; /*#fff*/
}

#footer hr {
    border-bottom-color: #fefefe; /*#fff*/
}

#footer input[type='submit'],
#footer input[type='reset'],
#footer input[type='button'],
#footer button,
#footer .button {
    background-color: #666666; /*#5a5a5a*/
    color: #fefefe !important; /*#ffffff*/
}

#footer input[type='submit']:hover,
#footer input[type='reset']:hover,
#footer input[type='button']:hover,
#footer button:hover,
#footer .button:hover {
    background-color: var(--main-red); /*#676767*/
}

#footer input[type='submit']:active,
#footer input[type='reset']:active,
#footer input[type='button']:active,
#footer button:active,
#footer .button:active {
    background-color: #333333; /*#4d4d4d*/
}

#footer input[type='submit'].alt,
#footer input[type='reset'].alt,
#footer input[type='button'].alt,
#footer button.alt,
#footer .button.alt {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #fff; /* check this !!!!!!!!!!!!!!!*/
    color: #ffffff !important; /* check this !!!!!!!!!!!!!!!*/
}

#footer input[type='submit'].alt:hover,
#footer input[type='reset'].alt:hover,
#footer input[type='button'].alt:hover,
#footer button.alt:hover,
#footer .button.alt:hover {
    background: rgba(255, 255, 255, 0.25); /* check this !!!!!!!!!!!!!!!*/
}

#footer input[type='submit'].alt:active,
#footer input[type='reset'].alt:active,
#footer input[type='button'].alt:active,
#footer button.alt:active,
#footer .button.alt:active {
    background-color: rgba(255, 255, 255, 0.2); /* check this !!!!!!!!!!!!!!!*/
}

#footer input[type='submit'].alt.icon:before,
#footer input[type='reset'].alt.icon:before,
#footer input[type='button'].alt.icon:before,
#footer button.alt.icon:before,
#footer .button.alt.icon:before {
    color: #f8d1d2; /* check this !!!!!!!!!!!!!!!*/
}

#footer input[type='submit'].special,
#footer input[type='reset'].special,
#footer input[type='button'].special,
#footer button.special,
#footer .button.special {
    background-color: #ffffff;
    color: #e5474b !important; /* check this !!!!!!!!!!!!!!!*/
}

#footer label {
    color: #fefefe; /*#ffffff*/
}

#footer input[type='text'],
#footer input[type='password'],
#footer input[type='email'],
#footer select,
#footer textarea {
    background: none;
    border-color: #666666; /*rgba(255, 255, 255, 0.25)*/
}

#footer input[type='text']:focus,
#footer input[type='password']:focus,
#footer input[type='email']:focus,
#footer select:focus,
#footer textarea:focus {
    border-color: #fefefe; /*#ffffff*/
    box-shadow: 0 0 0 1px #fefefe; /*#ffffff*/
}

#footer .select-wrapper:before {
    color: #fefefe; /*#fff*/
}

#footer input[type='checkbox'] + label,
#footer input[type='radio'] + label {
    color: var(--main-red); /*#f2a3a5*/
}

#footer input[type='checkbox'] + label:before,
#footer input[type='radio'] + label:before {
    background: none;
    border-color: #fefefe; /*#fff*/
}

#footer input[type='checkbox']:checked + label:before,
#footer input[type='radio']:checked + label:before {
    background-color: #fefefe; /*#ffffff*/
    border-color: #fefefe; /*#ffffff*/
    color: var(--main-red); /*#e5474b*/
}

#footer input[type='checkbox']:focus + label:before,
#footer input[type='radio']:focus + label:before {
    border-color: #fefefe; /*#ffffff*/
    box-shadow: 0 0 0 1px #fefefe; /*#ffffff*/
}

#footer ::-webkit-input-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

#footer :-moz-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

#footer ::-moz-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

#footer :-ms-input-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

#footer .formerize-placeholder {
    color: #fefefe !important; /*#f8d1d2*/
}

#footer .inner {
    max-width: 65em;
    width: calc(100% - 6em);
    margin: 0 auto;
}

@media screen and (max-width: 480px) {
    #footer .inner {
        max-width: 90%;
        width: 90%;
    }
}

#footer .copyright {
    font-size: 0.9em;
    margin: 0 0 2em 0;
    padding: 0;
    color: #fefefe; /*#f2a3a5*/
}

@media screen and (max-width: 980px) {
    #footer {
        padding: 4em 0 2em 0;
    }
}

@media screen and (max-width: 736px) {
    #footer {
        padding: 2em 0 0.1em 0;
    }
}
