#projects {
    background-color: white;
    background-image: linear-gradient(
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        url('../assets/images/background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.project-category h2 {
    font-family: 'ethnocentric';
    text-align: center;
    padding: 30px 0 0 0;
    margin: 0;
}

.projects-list {
    display: flex;
    justify-content: center;
}

.project-card {
    background: white;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 400px;
    margin: 20px;
}

.proj-img-cnt {
    height: 200px;
}

.proj-img-cnt img {
    width: 100%;
    height: 100%;
}

.proj-text-cnt {
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.proj-title h5 {
    margin-bottom: 5px;
}

.proj-summary {
    line-height: 1.2;
    font-size: 0.8em;
    height: 100%;
}

.project-wrapper {
    padding: 40px 0;
}

.project-wrapper .project-title {
    padding: 20px;
    display: flex;
}

.project-wrapper .project-title h1 {
    margin: auto;
}

.project-wrapper .full-project-view {
    background: white;
    height: 800px;
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.project-wrapper .full-project-img-ctn {
    display: flex;
    width: 500px;
    min-width: 500px;
    border-right: 1px solid #eaeaea;
}

.project-wrapper .full-project-img-ctn img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.project-wrapper .full-project-img-ctn .slick-slider {
    width: inherit;
    margin: auto;
}

.project-wrapper .full-project-desc-ctn {
    max-width: 1000px;
    overflow-y: auto;
    padding: 10px 30px;
}

.project-wrapper .project-actions {
    display: flex;
    padding: 20px;
}

.project-wrapper .project-actions a {
    margin: auto;
}
